import React from 'react'
import HeartBeatAnimation from '../components/HeartBeatAnimation'


function MainPage() {
  return (
    <div className='main-bg'>
        {/* <HeroComponent /> */}
        <HeartBeatAnimation />
    </div>
  )
}

export default MainPage