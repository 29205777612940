import {BrowserRouter, Routes, Route} from 'react-router-dom'
import MainPage from './pages/MainPage';
import ErrorPage from './pages/ErrorPage';
import SuccessPage from './pages/SuccessPage';
import LoadingComponent from './components/LoadingComponent';


function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route index element={<MainPage />} />
          <Route path="/home" element={<MainPage />} />
          <Route path="/success" element={<SuccessPage />} />
          <Route patch="*" element={<ErrorPage />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
