import React, { useEffect, useState, useRef } from 'react';
import zdj1 from '../assets/1rs.jpg';
import zdj2 from '../assets/2rs.jpg';
import zdj3 from '../assets/3rs.jpg';
import zdj4 from '../assets/4rs.jpg';
import zdj5 from '../assets/5rs.jpg';
import zdj6 from '../assets/6rs.jpg';
import zdj7 from '../assets/7rs.jpg';
import zdj8 from '../assets/8rs.jpg';
import zdj9 from '../assets/9rs.jpg';
import zdj10 from '../assets/10rs.jpg';
import zdj11 from '../assets/11rs.jpg';
import zdj12 from '../assets/12rs.jpg';
import LoadingComponent from '../components/LoadingComponent';

const App = () => {
  const [isVisible, setIsVisible] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const divRefs = [
    useRef(), useRef(), useRef(), useRef(),
    useRef(), useRef(), useRef(), useRef(),
    useRef(), useRef(), useRef(), useRef(),
    useRef(), useRef(),
  ];

  useEffect(() => {
    const images = [zdj1, zdj2, zdj3, zdj4, zdj5, zdj6, zdj7, zdj8, zdj9, zdj10, zdj11, zdj12];
    let loadedImages = 0;

    const loadImage = (imageSrc) => {
      const image = new Image();
      image.src = imageSrc;
      image.onload = () => {
        loadedImages++;
        if (loadedImages === images.length) {
          setIsLoading(false);
        }
      };
    };

    images.forEach((imageSrc) => loadImage(imageSrc));

    const handleScroll = () => {
      const newIsVisible = divRefs.map((ref) => {
        const rect = ref.current.getBoundingClientRect();
        return rect.top < window.innerHeight && rect.bottom >= 0;
      });
      setIsVisible(newIsVisible);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const timeoutId = setTimeout(scrollToNextSection, 6000);
    return () => clearTimeout(timeoutId);
  }, []);

  const scrollToNextSection = () => {
    const windowHeight = window.innerHeight;
    window.scrollBy({ top: windowHeight, behavior: 'smooth' });
    setTimeout(scrollToNextSection, 6000);
  };

  if (isLoading) {
    return <div><LoadingComponent /></div>;
  }

  return (
    <div>
      <div className='bg-[#B98ED9] w-full'>
        <div className='max-w-[1240px] mx-auto'>
          {divRefs.map((ref, index) => (
            <div key={index} ref={ref} className='h-screen flex items-center justify-center section'>
              {index === 0 && (
                <h2 className={`text-[50px] text-white ${isVisible[index] ? 'animate-zoom-in' : ''}`}>
                376 dni razem...
                </h2>
              )}
              {index === 1 && (
                <h2 className={`text-[50px] text-white ${isVisible[index] ? 'animate-zoom-in' : ''}`}>
                czyli 9024 godziny
                </h2>
              )}
              {index === 2 && (
                <h2 className={`text-[50px] text-white ${isVisible[index] ? 'animate-zoom-in' : ''}`}>
                541 440 minut
                </h2>
              )}
              {index === 3 && (
                <h2 className={`text-[50px] text-white ${isVisible[index] ? 'animate-zoom-in' : ''}`}>
                32 486 400 sekund
                </h2>
              )}
              {index === 4 && (
                <div className='grid md:grid-cols-2 section mx-auto text-center my-auto'>
                <img src={zdj2} alt="" className={`w-[600px] mx-auto my-auto ${isVisible[index] ? 'animate-slide-from-left' : ''}`}/>
                <img src={zdj1} alt="" className={`w-[600px] mx-auto my-auto ${isVisible[index] ? 'animate-slide-from-right' : ''}`}/>
                </div>
              )}
              {index === 5 && (
                <h2 className={`text-[50px] text-white ${isVisible[index] ? 'animate-zoom-in' : ''}`}>
                Kilkaset pięknych chwil...
                </h2>
              )}
              {index === 6 && (
                <div className='grid md:grid-cols-2 section mx-auto text-center my-auto'>
                <img src={zdj3} alt="" className={`w-[600px] mx-auto my-auto ${isVisible[index] ? 'animate-slide-from-left' : ''}`}/>
                <img src={zdj4} alt="" className={`w-[600px] mx-auto my-auto ${isVisible[index] ? 'animate-slide-from-right' : ''}`}/>
                </div>
              )}
              {index === 7 && (
                <div className='grid md:grid-cols-2 section mx-auto text-center my-auto'>
                <img src={zdj5} alt="" className={`w-[600px] h-[500px] mx-auto my-auto ${isVisible[index] ? 'animate-slide-from-left' : ''}`}/>
                <img src={zdj6} alt="" className={`h-[500px] mx-auto my-auto ${isVisible[index] ? 'animate-slide-from-right' : ''}`}/>
                </div>
              )}
              {index === 8 && (
                <h2 className={`text-[50px] text-white ${isVisible[index] ? 'animate-zoom-in' : ''}`}>
                A to dopiero początek!
                </h2>
              )}
              {index === 9 && (
                <div className='grid md:grid-cols-2 section mx-auto text-center my-auto'>
                <img src={zdj7} alt="" className={`w-[600px] mx-auto my-auto ${isVisible[index] ? 'animate-slide-from-left' : ''}`}/>
                <img src={zdj8} alt="" className={`w-[600px] mx-auto my-auto ${isVisible[index] ? 'animate-slide-from-right' : ''}`}/>
                </div>
              )}
              {index === 10 && (
                <div className='grid md:grid-cols-2 section mx-auto text-center my-auto'>
                <img src={zdj9} alt="" className={`h-[600px] mx-auto my-auto ${isVisible[index] ? 'animate-slide-from-left' : ''}`}/>
                <img src={zdj10} alt="" className={`h-[600px] mx-auto my-auto ${isVisible[index] ? 'animate-slide-from-right' : ''}`}/>
                </div>
              )}
              {index === 11 && (
                <div className='grid md:grid-cols-2 section mx-auto text-center my-auto'>
                <img src={zdj11} alt="" className={`h-[600px] mx-auto my-auto ${isVisible[index] ? 'animate-slide-from-left' : ''}`}/>
                <img src={zdj12} alt="" className={`h-[600px] mx-auto my-auto ${isVisible[index] ? 'animate-slide-from-right' : ''}`}/>
                </div>
              )}
              {index === 12 && (
                <h2 className={`text-[50px] text-white ${isVisible[index] ? 'animate-zoom-in' : ''}`}>
                Bardzo Cię kocham!
                </h2>
              )}
              {index === 13 && (
                <h2 className={`text-[50px] text-white ${isVisible[index] ? 'animate-zoom-in' : ''}`}>
                ps. wczoraj nie byłem na siłowni
                </h2>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default App;
