import React from 'react'

function LoadingComponent() {
  return (
    <div className='w-full main-bg'>
        <div className='max-w-[1240px] mx-auto h-screen flex flex-col justify-center py-[10rem] text-center'>
            <h2 className='text-[white] text-[60px] font-black'>Ładowanie zasobów...</h2>
            <h2 className='text-[white] text-[40px]'>Jeszcze chwilę robaczku</h2>
        </div>
    </div>
  )
}

export default LoadingComponent