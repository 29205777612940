import React, { useEffect, useState } from 'react';
import { FaHeart } from 'react-icons/fa';

const HeartBeatAnimation = () => {
  const [visibleHearts, setVisibleHearts] = useState([]);
  const [button1Position] = useState({ top: '50%', left: 'calc(50% - 125px)', transform: 'translate(-50%, -50%)' });
  const [button2Position, setButton2Position] = useState({ top: '50%', left: 'calc(50% + 125px)', transform: 'translate(-50%, -50%)' });
  const [containerSize, setContainerSize] = useState({ width: 0, height: 0 });

  useEffect(() => {
    generateHearts();
    updateContainerSize();
    window.addEventListener('resize', updateContainerSize);
    return () => window.removeEventListener('resize', updateContainerSize);
  }, []);

  const generateHearts = () => {
    const newHearts = [];
    const numberOfHearts = 20;

    for (let i = 0; i < numberOfHearts; i++) {
      const style = {
        top: `${Math.random() * 100}vh`,
        left: `${Math.random() * 100}vw`,
        animationDelay: `-${Math.random() * 5}s`
      };
      newHearts.push(<FaHeart key={i} className="heart" style={style} />);
    }

    setVisibleHearts(newHearts);
  };

  const updateContainerSize = () => {
    const container = document.getElementById('content-container');
    if (container) {
      const { offsetWidth, offsetHeight } = container;
      setContainerSize({ width: offsetWidth, height: offsetHeight });
    }
  };

  const handleSecondButtonClick = () => {
    const newTop = Math.random() * 2 * (containerSize.height - 152); // 52 is button height
    const newLeft = Math.random() *  2 * (containerSize.width - 200); // 200 is button width
    setButton2Position({ top: `${newTop}px`, left: `${newLeft}px`, transform: 'translate(-50%, -50%)' });
  };

  return (
    <div className="heartbeat-container">
      <div className="background" />
      <div className="heart-container">
        {visibleHearts}
      </div>
      <div id="content-container" className="text-container glass">
        <h1 className="centered-text text-[50px] text-white">Zostaniesz moją walentynką?</h1>
        <div className='flex mx-auto justify-center gap-10'>
          <div style={{ position: 'relative', width: containerSize.width, height: containerSize.height }}>
            <a href="/success"><button className='z-[999] hover:scale-105 duration-300 bg-transparent text-white border-[1px] text-[18px] rounded-full w-[200px] h-[52px] items-center justify-center' style={{ position: 'absolute', ...button1Position }}>TAK!!!</button></a>
            <button onClick={handleSecondButtonClick} className='z-[999] hover:scale-105 duration-300 bg-transparent text-white border-[1px] text-[18px] rounded-full w-[200px] h-[52px] items-center justify-center' style={{ position: 'absolute', ...button2Position }}>NIE ://</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HeartBeatAnimation;
